@import 'styles/variables/colors.scss';
@import 'styles/variables/mqueries.scss';

section#content.no-fixed section#sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 330px;
    box-sizing: border-box;
    overflow-y: scroll;
    border-left: solid 1px #ddd;
    @media #{$all-narrow-sq} {
        display: none;
    }
}

section#content.fixed section#sidebar {
    position: fixed;
    top: 50px;
    bottom: 0;
    right: 0;
    width: 330px;
    box-sizing: border-box;
    overflow-y: scroll;
    border-left: solid 1px #ddd;
    @media #{$all-narrow-sq} {
        display: none;
    }
}


section#sidebar.on {
    position: fixed;
    top: 50px;
}

section#sidebar.hidden {
    display:none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
section#sidebar::-webkit-scrollbar {
    display: none;
}
section#sidebar.on-progra {
    position: fixed;
    top: 130px;
}
/* Hide scrollbar for IE, Edge and Firefox */
section#sidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  z-index:32;
}
// section#sidebar.on-video {
//     width: 600px!important;
// }