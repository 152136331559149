@import 'styles/variables/mqueries.scss';

#search-results {
  min-height: 78vh;
  position: relative;
  padding-bottom: 20px;
  padding-top: 60px;
}

#search-results h1 {
  left:5%;
  width:90%;
  margin:auto;
  margin-bottom: 10px;
  clear:both;
}

#search-results .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  left:5%;
  width:90%;
  margin:auto;
}

#search-results .list article {
  padding-top:20px;
  width: 21%;

  @media #{$wide} {
    width: 30%;
  }

  @media #{$square} {
    width: 42%;
  }

  @media #{$all-narrow} {
    width: 90%;
  }
}

#search-results .list article h2 {
  font-size: 1.3em;
}

#search-results .list article .image_container{
  height: 250px;
  margin-bottom:10px;
}

#search-results .list article .image_container .image {
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#search-results .list article .date {
  margin-bottom: 10px;
  text-align: right;
}
