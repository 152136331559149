@import 'styles/variables/mqueries.scss';
@import 'styles/variables/colors.scss';
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Lora:300,400,700');

* {
  margin:0;
  padding:0;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: $text;
  overflow-x: hidden;

  @media #{$super-wide} {
      font-size:14px;
  }

  @media #{$wide} {
      font-size:12px;
  }

  @media #{$square} {
      font-size:10px;
  }

  @media #{$all-narrow} {
      font-size:12px;
  }

}

.wrapper-central {
  position:relative;
  margin: auto;
  height:100%;

  @media #{$super-wide} {
      width:800px;
  }

  @media #{$wide} {
      width:600px;
  }

  @media #{$square} {
      width:800px;
  }

  @media #{$all-narrow} {
      width:95%;
  }
}

a {
    text-decoration: none;
    color: $naranja;
}

a:hover {
    color: $gris2;
}
