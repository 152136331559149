$naranja: #ff7500;
$negro: #111;
$negro1: #333;
$gris0: #555;
$gris1: #666;
$gris2: #777;
$gris3: #aaa;
$gris4: #ddd;

$text: $gris1;
