@import 'styles/variables/colors.scss';

#site-post {
    position: relative;
    font-family: 'Lora', sans-serif;
    color: #010101;
    margin-top: 30px;
}

#site-post .post_content {
  margin: auto;
  & .date {
    color: #7F7F7F;
    width: fit-content;
    margin-left: 10%;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid $naranja;
    text-align: left;
    font-size: 10px;
  }
}
#site-post article.the-post{
    max-width: 550px;
    & h1{
        text-align: left;
        margin: 10px auto;
        width: 80%;
    }
    & div.content{
        text-align: left;
        margin: 10px auto;
        width: 80%;
    }
    & div.excerpt {
        text-align: left;
        margin: 10px auto;
        width: 80%;
    }
    & .image_wrapper{
        border-top: 2px solid $naranja;
    }

    & .audio .lnk-player {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 30px;
        display: flex;
        flex-wrap: nowrap;
        
        & .play {
            position:relative;
            padding-top: 1px;
            width: 30px;
            border: 1px solid #999;
            border-radius: 50%;
            cursor: pointer;

            & i {
                font-size: 12px;
                color: $naranja;
                margin-left: 11px;
                margin-top: 8px;
            }
        }

        & .play:hover {
            background-color: #999;
            transition: all 0.2s;
        
        }

        & .download {
            position:relative;
            padding-top: 1px;
            width: 30px;
            border: 1px solid #999;
            border-radius: 50%;
            cursor: pointer;
            margin-left: 5px;

            & a {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            & i {
                font-size: 12px;
                color: #ff7500;
                margin-left: 9px;
                margin-top: 8px;
            }
        }

        & .download:hover {
            background-color: #999;
            transition: all 0.2s;
        
        }

        & .description {
            position: absolute;
            top: 2px;
            left: 43px;
            font-size: 10px;
            color: #777;

            & .texto1 {
                color: $naranja;
                font-weight: bold;
            }

            & .separador {
                display: inline-block;
                width: 1px;
                height: 8px;
                border-left: 1px solid #999;
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        & .time {
            position:relative;
            margin: 0 10px;
            width: auto;
            flex-grow: 2;
            flex-shrink: 2;

            & .time-control-line {
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #999;
                position: absolute;
                left: 0;
                top: 20px;
                z-index: 40;
                cursor: pointer;
            }

            & .time-control-line-active {
                position: absolute;
                height: 2px;
                background-color: $naranja;
                position: absolute;
                left: 0;
                top: 20px;
                z-index: 42;
            }

            & input {
                z-index: 45;
                position: absolute;
                left: 0;
                top: 5px;
                width: 100%;
                height: 33px;
            }
        }

        & .volume {
            position:relative;
            width: 15%;
            flex-shrink: 1;
            margin-left: 10px;

            & .volume-control-line {
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #999;
                position: absolute;
                left: 0;
                top: 20px;
                z-index: 40;
                cursor: pointer;
            }

            & .volume-control-line-active {
                position: absolute;
                height: 2px;
                background-color: $naranja;
                position: absolute;
                left: 0;
                top: 20px;
                z-index: 42;
            }

            & input {
                z-index: 45;
                position: absolute;
                left: 0;
                top: 5px;
                width: 100%;
                height: 33px;
            }
        }

        & .current-time {
            position:relative;
            padding-top: 15px;
            color: #555;
            width: 65px;
            text-align: right;
            flex-shrink: 0;
            font-family: arial, monospace;
            font-size: 11px;
        }
    }
}

#site-post section#share-buttons{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 200px;
    left: 80px;
    width: fit-content;
    & button{
        margin-bottom: 10px;
        & svg{
            width: 25px;
            height: 25px;
            border: 1px solid  #7F7F7F;
            border-radius: 50%;
            & circle {
                fill: #FFFF;
            }
            & path {
                fill: #7F7F7F
            }
        }
    }
}




#site-post table.inPost_table{
	width:90%;
	margin:auto;
	margin-top:10px;
    margin-bottom:10px;
    border-collapse: collapse;
}

#site-post table.inPost_table td{
	border:1px solid #999;
    padding:10px; 
}

#site-post table.inPost_table th {
    color: #008dc2;
    font-size: 1.2em;
}

#site-post article .date {
    font-family: 'Monserrat', sans-serif;
    font-size: 1.3em !important;
}

#site-post article .audio {
    font-family: 'Monserrat', sans-serif;
}

#site-post article div.excerpt {
    font-size: 1.6em;
    line-height: 150%;
    color: #555;
}

#site-post article div.content {
    font-size: 1.5em;
    line-height: 150%;
}